body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.home-page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Styling */
.card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background: white;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-header, .card-body, .card-footer {
  padding: 10px; /* Ensure all sections have same padding */
}

/* Remove line between card-header and body */
.card-header {
  border-bottom: none !important;
  background: none;
}

/* Card Header */
.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.app-logo {
  width: 60px;
  height: 60px;
  border-radius: 12%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.card-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

/* Description */
.card-body {
  padding: 10px;
}

.card-text {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 8px;
}

/* Pricing Section */
.pricing-section {
  display: flex;
  align-items: center;
}


.pricing-section .original-price {
  font-size: 0.9rem;
  color: #757575;
  margin: 0;
}

.pricing-section .discounted-price {
  font-size: 1rem;
  font-weight: bold;
  color: #d32f2f;
  margin-left: 8px;
}

/* Lifetime Badge */
.lifetime-label {
  display: inline-block;
  background-color: #ffd54f;
  color: #333;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 15px;
  text-align: center;
  margin-top: 4px;
}

/* Footer Section */
.card-footer {
  text-align: center;
  margin-top: auto;
}

.visit-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.visit-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Card Grid Styling */
.row {
  row-gap: 20px;
}
