.submit-page {
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.submit-content .top-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
}

.submit-content .top-section section {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #f9f9f9;
}

.submit-content h2 {
  color: #1e3d58;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.submit-content p,
.submit-content ul {
  font-size: 1rem;
  line-height: 1.6;
}

.submit-content ul {
  padding-left: 20px;
}

.submit-content ul li {
  margin-bottom: 10px;
}

.submit-content a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.submit-content a:hover {
  text-decoration: underline;
}

.submission-guidelines {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #f9f9f9;
}
