.navbar {
  background-color: #007bff; /* Vibrant blue background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 0.5rem 1rem;
}

.navbar-brand {
  font-weight: bold;
  color: #ffffff !important; /* Ensure white text for branding */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  font-size: 1.5rem;
}

.navbar-brand:hover {
  color: #dfe6f5 !important; /* Slightly lighter hover effect */
  transform: scale(1.1); /* Adds a slight zoom effect */
}

.nav-link {
  color: #ffffff !important; /* Ensure white text for navigation links */
  font-weight: 500;
  margin-right: 1rem;
  transition: color 0.3s ease, transform 0.3s ease;
  font-size: 1rem;
}

.nav-link:hover {
  color: #dfe6f5 !important; /* Lighter white-blue for hover */
  transform: translateY(-2px); /* Subtle upward animation */
  text-decoration: none;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  color: #0000;
  border-radius: 2px;
  height: 3px;
  width: 25px;
  display: block;
  margin: 5px auto;
  position: relative;
}

.navbar-toggler-icon::after,
.navbar-toggler-icon::before {
  content: '';
  position: absolute;
  background-color: #0000;
  height: 3px;
  width: 25px;
  border-radius: 2px;
  left: 0;
}

.navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler-icon::after {
  bottom: -8px;
}

.container {
  max-width: 1200px; /* Centered and constrained content */
}
