.footer-deals {
  background-color: #007bff; /* Vibrant blue matching the theme */
  color: #ffffff; /* White text for high contrast */
  text-align: center;
  padding: 1rem 0;
  font-size: 1rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.footer-deals p {
  margin: 0;
  font-weight: 500;
}

.footer-deals p:hover {
  color: #dfe6f5; /* Slightly lighter text on hover */
  transition: color 0.3s ease;
}
