.wall-page {
  display: flex;
  flex-direction: column;
  min-height: 90vh; /* Ensure the wall-page fills the viewport height */
}

.wall-header {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.wall-header h1 {
  font-size: 2.4rem;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

/* Main content styling */
.wall-content {
  flex: 1; /* Pushes the footer to the bottom if content is short */
}

/* Month-Year Section Styling */
.month-year-section {
  margin-top: 30px;
  margin-bottom: 10px;
}

.month-year-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0px;
  color: #555;
}

/* App Wall Styling */
.app-wall {
  display: flex;
  flex-wrap: wrap;
  gap: 0px; /* Minimal gap between items */
  margin: 0 0; /* Left and right spacing */
  justify-content: flex-start;
}

/* App Logo Styling */
.app-link {
  text-decoration: none;
  width: 60px; /* Smaller size for logos */
  height: 60px; /* Smaller size for logos */
  object-fit: contain; /* Ensure proper scaling */
}

.app-logo {
  width: 60px; /* Smaller size for logos */
  height: 60px; /* Smaller size for logos */
  object-fit: contain; /* Ensure proper scaling */
  border-radius: 8px; /* Slightly rounded corners */
  border: 1px solid #ddd; /* Optional subtle border */
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.app-logo:hover {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* No Apps Message */
.no-apps-message {
  text-align: center;
  margin-top: 40px;
  color: #666;
}

.no-apps-message h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.no-apps-message p {
  font-size: 1rem;
  color: #555;
}
